.wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
}

.errorBoundary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.errorBoundary h1 {
  color: #000;
  font-weight: 500;
}

.goBack {
  font-size: 20px;
  color: #020266;
  font-weight: 300;
}

.goBack:hover {
  text-decoration: none;
  color: #5959c2;
}
