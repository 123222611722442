/* @font-face {
  font-family: "Proxima-Nova";
  src: url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");
} */
@import url("https://rsms.me/inter/inter.css");

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

:root {
  --theme-color: rgba(0, 0, 0, 0.8);
  --primary-color: #020266;
  --theme-color2: #020266;
  --border-color: #020266; // #004d47;
  --button-color: #020266; // #004d47;
  --theme-gray: #e6e5e5; // #004d47;
  --theme-green: #08a52a; // #004d47;
  --chat-color: #05728f;
}

*,
body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  box-shadow: none;
  background-color: var(--theme-color);
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
  border-radius: 0px;
}

body {
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 100vh;
  font-size: 0.9rem !important;
  // font-family: "Proxima-Nova", Fallback, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  p {
    margin-bottom: 0rem;
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.main {
  //   margin-top: 66px;
  //   display: flex;
  min-height: 100vh;
  //   padding: 40px 0 50px 0;
}

.is-loading {
  filter: blur(2px);
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0 !important;
}

input,
select {
  border: 1px solid rgba(0, 0, 0, 0.278);
  outline: none;
  border-radius: 5px;
  padding: 0 6px;
  height: 2rem;
}

button:focus {
  outline: none !important;
}

input[type="file"]::-webkit-file-upload-button {
  height: 2rem;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

input[type="file"] {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
}

input[type="date"] {
  padding-right: 0;
}

a {
  // color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

.form-item {
  margin-top: 10px;
}

//Table Defaults
.dropdown-item {
  padding: 3px !important;
  font-size: 0.6rem !important;
}

.action-dropdown {
  font-size: 0.6rem !important;
  color: black !important;
  padding: 3px !important;
  background-color: white !important;
  border-color: black !important;
}

// .table-container {
//   width: 100%;
//   overflow: hidden;
//   overflow-x: auto;
// }

.reaps-table {
  width: 100%;
  font-size: 12px;
  margin-bottom: 1rem;
  word-break: break-all;

  // td {
  //   word-break: break-word;
  // }
  td,
  th {
    padding: 4px;
  }

  .tabel-item {
    min-width: 100px;
  }

  thead {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  }
}

.modal-title {
  font-size: 18px !important;
  font-weight: bolder !important;
}

.table-filter {
  input {
    height: 30px;
    margin-right: 2px;
  }

  select {
    height: 30px;
    margin-right: 2px;
  }

  margin: 1.2rem 0;
}

.paint-row {
  background-color: #d4d4d4;
}

.btn {
  margin: 0 10px;
}

.modal-body {
  position: relative;
  /* flex: 1 1 auto; */
  padding: 1rem;
  justify-content: center;
  display: flex;
}

.btn-edit {
  border: solid 1px var(--theme-color2);
  color: var(--theme-color2);
  background-color: white;
  padding: 0.2rem;
  border-radius: 5px;
  font-size: 0.6rem;
  margin: 3px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-approve {
  border: solid 1px var(--theme-green);
  color: var(--theme-green);
  background-color: white;
  padding: 0.2rem;
  border-radius: 5px;
  font-size: 0.6rem;
  margin: 3px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-edit:active,
.btn-delete:active,
.btn-approve:active {
  // font-size: 10px;
}

.btn-delete {
  border: solid 1px red;
  color: red;
  background-color: white;
  padding: 0.2rem;
  border-radius: 5px;
  font-size: 0.6rem;
  margin: 3px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-delete:hover,
.btn-edit:hover,
.btn-approve:hover {
  filter: opacity(0.5);
}

.pagination-btn {
  margin: 1.2rem 0;

  button {
    min-width: 25px;
    height: 25px;
    font-size: 13px;
    margin: 2px;
    border: none;
    border: solid 1px var(--border-color);
    color: var(--button-color);
    border-radius: 5px;
  }

  button:active {
    font-size: 10px;
  }

  .paginate-active {
    color: white;
    font-weight: bold;
    background-color: var(--border-color);
    border-radius: 5px;
  }
}

.create-body {
  margin-top: 1.5rem;

  h5 {
    font-size: 14px;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    font-weight: bold;
    color: black;
  }

  img {
    height: 100px;
    width: 100px;
  }

  p {
    padding-bottom: 0 !important;
  }

  button {
    width: 4.3rem;
  }
}

.create-form-body {
  input {
    width: 80%;
    max-width: 400px;
  }

  border: 1px var(--theme-gray) solid;
  border-radius: 0.4rem;
  padding: 1rem;
}

// DASHBOARD body
.dashboard-body {
  padding: 1.2rem;
  max-width: 1000px;
  margin: auto;

  .dashboard-body-content {
    border: 1px solid rgba(128, 128, 128, 0.664);
    border-radius: 5px;
    padding: 1rem;
  }
}

// MISC
.notice-general {
  font-size: small;
  text-align: center;
  color: #020266;
  font-weight: 700;
}

.table-link {
  a {
    color: #007bff;
  }
}

// Style for error toast

.Toastify__toast--error {
  background-color: rgba(245, 243, 243, 0.9) !important;
  border: 1px solid red !important;
  color: red;
  font-weight: 600;
}

.Toastify__close-button.Toastify__close-button--error {
  svg {
    path {
      fill: red;
    }
  }
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar--error {
  background-color: red;
}

.latAndLongButton {
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: 4px;
  color: var(--primary-color);

  &:hover {
    transition: all 0.2s;
    background-color: var(--primary-color);
    color: white;
  }
}
