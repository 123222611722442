.nav-hambuger {
  display: none;
}
.navbar-toggler {
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  background-color: white;
  font-size: 1rem;
  /* border-color: rgba(0, 0, 0, 0.1); */
}
.navbar-toggler span {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  cursor: pointer;
  height: 1.5rem;
  padding: 0;
  color: rgba(0, 0, 0, 0);
  width: 3.3rem;
  background: url("./assets/images/calendar-interface-symbol-tool.svg");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 750px) {
  .nav-hambuger {
    display: block;
    transition: all 0.5s ease-in-out;
  }
}

.reaps-btn {
  margin-top: 15px;
  width: 100%;
  height: 40px;
  outline: none;
  background-color: var(--theme-color2);
  border: 1px solid var(--theme-color2);
  color: white;
  font-weight: bold;
  max-width: 600px;
}
.reaps-btn2 {
  height: 30px;
  outline: none;
  background-color: var(--theme-color2);
  border: 1px solid var(--theme-color2);
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  max-width: 600px;
  padding: 0 8px;
}
.reaps-btn.small {
  width: 100px;
  height: 35px;
}

div.no-record {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 12px;
  font-weight: bold;
}

/* div .main-div {
  max-width: 1440px !important;
  margin: 0 auto !important;
} */